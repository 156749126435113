import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { Avatar, Card } from 'components/atoms';
import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import { StaffCard } from 'components/molecules/StaffCard';
import { TimeBlock } from 'components/molecules/TimeBlock';
import { LoadingProvider } from 'contexts/LoadingContext';
import { checkIsMobile, TimeHelper } from 'helpers';
import { getShortName } from 'helpers/StringHelper';
import _ from 'lodash';
import { EBookingInputFrom, IBooking, IEmployee } from 'models';
import { IStylistAndBlockTimeRequest } from 'models/RequestModels';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerBookingActions } from 'redux/actions';
import { RootState } from 'redux/configuration/rootReducer';
import BookingApiService from 'services/BookingApiService';
import { I18n, translations, _t } from 'utils';
import { noStylist, StaffItem } from './SelectStaff';

const checkDisabled = (date: any) => {
	const today = new Date();
	today.setDate(today.getDate() - 1);
	const newDate = new Date(date);
	if (today.getTime() > newDate.getTime()) {
		return true;
	}
	return false;
};

const isItWeekEnd = (day: any) => {
	let dateValue = day.getDay();
	if (dateValue === 6 || dateValue == 0) return true;
	else return false;
};

const groupTimeBlocks = (timeBlocks: any) => {
	return timeBlocks
		.filter((x: any) => x.unavailable === false)
		.reduce((r: any, a: any) => {
			r[a.label.slice(-2)] = [...(r[a.label.slice(-2)] || []), a];
			return r;
		}, {});
};

const dates = (current: Date, amount: number) => {
	var listDate = new Array();
	// Get 6 days next
	for (var i = 0; i < amount; i++) {
		listDate.push(new Date(current));
		current.setDate(current.getDate() + 1);
	}
	return listDate;
};

const isCompareDate = (selectedDay: Date, day: Date) => {
	return formatDay(selectedDay) === formatDay(day);
};

const formatDay = (days: Date) => moment(days).format('MM-DD-YYYY');

interface IDateCalendar {
	selectedDay: any;
	daysOfWeek: any[];
	currentMonthYear: any[];
	selectedTime: any;
	existDisplay: any[];
}

interface ICalendarProps {
	dateCalendar: IDateCalendar;
	handleDecreaseWeek: () => void;
	handleChooseCalendar: (x: Date) => void;
	handleIncreaseWeek: () => void;
	isMobile: boolean;
}

const Calendar = (props: ICalendarProps) => {
	return (
		<div className="bg-white rounded-md">
			<div className="mb-4">
				<div className="flex flex-row gap-2 justify-center pb-4">
					<div className="basis-1/12 flex items-center justify-center">
						<div
							className="cursor-pointer"
							onClick={() => props.handleDecreaseWeek()}
						>
							<Icon path={mdiChevronLeft} />
						</div>
					</div>
					<div className="basis-5/6 grid grid-cols-4 md:grid-cols-6 gap-2">
						{
							(props.dateCalendar.existDisplay =
								[] &&
								props.dateCalendar.daysOfWeek?.map((x: any, index: number) => {
									let dateView = moment(x).format('MMM');
									const exist = props.dateCalendar.existDisplay.find(
										(y: any) => y === moment(x).format('MMM')
									);
									if (!exist) {
										props.dateCalendar.existDisplay.push(
											moment(x).format('MMM')
										);
									}
									const obj = (
										<div key={index}>
											<h4 className="m-0 ml-2 text-xl">
												{exist ? <span>&nbsp;</span> : dateView}
											</h4>
											<Button
												small
												className={clsxm(
													'text-black text-sm font-normal bg-white border-base-300 px-[11px] py-[8px] min-h-[60px] w-full min-w-0',
													isItWeekEnd(x) && 'bg-base-200',
													isCompareDate(props.dateCalendar.selectedDay, x) &&
														'customer-booking-background text-white',
													checkDisabled(x) && 'opacity-30 cursor-not-allowed'
												)}
												disabled={checkDisabled(x) ? true : false}
												onClick={() => props.handleChooseCalendar(x)}
											>
												{moment(x).format('ddd')} <br /> {x.getDate()}
											</Button>
										</div>
									);
									return obj;
								}))
						}
					</div>
					<div className="basis-1/12 flex items-center justify-center">
						<div
							className="cursor-pointer"
							onClick={() => {
								props.handleIncreaseWeek();
							}}
						>
							<Icon path={mdiChevronRight} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

interface ITimeBlockProps {
	timeBlocks: any;
	stylist: IEmployee[];
	dateCalendar: IDateCalendar;
	handleChooseTime: (item: any) => void;
	handleChooseOtherStylist: (stylist: Partial<IEmployee>) => void;
	isMobile: boolean;
}
const TimeBlocks = (props: ITimeBlockProps) => {
	const [otherStaffs, setOtherStaffs] = useState<IEmployee[]>([]);
	const [loading, setLoading] = useState<boolean>();
	const booking = useSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	);
	const isRequireStylist = useSelector(
		(state: RootState) =>
			state.BranchReducer.currentBranch?.technicianSelectionWhenBookingOnline
	)!;

	useEffect(() => {
		_.isEmpty(props.timeBlocks) &&
			getAvailableStylistAndTimeBlocks(props.dateCalendar);
	}, [props.timeBlocks]);

	const getAvailableStylistAndTimeBlocks = async (day: any) => {
		setLoading(true);
		const param: IStylistAndBlockTimeRequest = {
			date: formatDay(day),
			// serviceId: 'bb32d366-8aff-4498-f47e-08d9a12237eb', // TODO
			serviceId: booking?.bookingDetails![0].itemId,
			time: booking?.bookingDetails![0].startAtExpected as unknown as string,
			inputFrom: EBookingInputFrom.OnlineSite,
			bookingId: booking?.id,
			duration: _.sumBy(booking?.bookingDetails, (x) => x.item?.duration || 0),
		};
		const response = await BookingApiService.getAvailableStylistAndTimeBlocks(
			param
		);
		if (response?.succeeded) {
			response.data!.timeBlocks.map((item: any) => {
				item.label = TimeHelper.toTimeZone(item.time).format('hh:mmA');
			});
			const stylistsCanDoService = isRequireStylist
				? response.data!.stylists.filter(
						(stylist) => stylist.id !== booking?.bookingDetails![0].stylistId
				  )
				: ([noStylist].concat(response.data!.stylists) as IEmployee[]);
			setOtherStaffs(stylistsCanDoService);
		}
		setLoading(false);
	};

	return (
		<div className="bg-white rounded-md p-5">
			{props.timeBlocks?.AM ? (
				<>
					<div>
						<h5 className="m-0 font-semibold text-black">
							{I18n.t(_t(translations.customerBooking.morning))}
						</h5>
					</div>
					<div className="grid grid-cols-3 gap-2 m-0 md:grid-cols-5 lg:grid-cols-6 2xl:grid-cols-8">
						{props.timeBlocks?.AM?.map((item: any, index: number) => (
							<div className="w-full flex justify-center" key={index}>
								<TimeBlock
									id={`${index}`}
									label={item.time}
									onClick={() => {
										props.handleChooseTime(item);
									}}
									active={props.dateCalendar.selectedTime === item.label}
									className={clsxm(
										'border-base-200',
										'hover:customer-booking-background',
										props.dateCalendar.selectedTime === item.label &&
											'customer-booking-background'
									)}
								/>
							</div>
						))}
					</div>
				</>
			) : null}
			{props.timeBlocks?.PM ? (
				<>
					<div className="mt-4">
						<h5 className="m-0 font-semibold text-black">
							{I18n.t(_t(translations.customerBooking.afternoon))}
						</h5>
					</div>
					<div className="grid grid-cols-3 gap-2 m-0 md:grid-cols-5 lg:grid-cols-6 2xl:grid-cols-8">
						{props.timeBlocks?.PM?.map((item: any, index: number) => (
							<div className="w-full flex justify-center">
								<TimeBlock
									id={`${index}`}
									label={item.time}
									onClick={() => {
										props.handleChooseTime(item);
									}}
									active={props.dateCalendar.selectedTime === item.label}
									className={clsxm(
										'border-base-200',
										'hover:customer-booking-background',
										props.dateCalendar.selectedTime === item.label &&
											'customer-booking-background'
									)}
								/>
							</div>
						))}
					</div>
				</>
			) : null}
			{_.isEmpty(props.timeBlocks) && (
				<div className="text-center">
					<h5 className="m-0">
						{I18n.t(_t(translations.customerBooking.stylistNotWorking))}
					</h5>
					{!_.isEmpty(props.stylist) &&
						(loading ? (
							'Loading'
						) : (
							<div className="text-left flex overflow-auto mx-auto">
								{otherStaffs
									.filter((x) => x.canBookedOnline)
									// .filter((e) => e.canBookedOnline)
									.map((stylist) => {
										const stylistName = `${stylist.firstName || ''} ${
											stylist.lastName || ''
										}`;
										return (
											<Card
												className="m-2 p-2 px-0 min-w-[180px] max-w-[180px] text-center cursor-pointer hover:animate-pop"
												onClick={() => {
													props.handleChooseOtherStylist(stylist);
												}}
											>
												<Avatar
													className=""
													small
													source={stylist.imageUrl}
													name={getShortName(stylistName)}
												/>
												<span className="text-black font-semibold">
													{stylistName}
												</span>
											</Card>
										);
									})}
							</div>
						))}
				</div>
			)}
		</div>
	);
};

export const SelectStaffCalendar = () => {
	const isMobile = checkIsMobile();
	const dispatch = useDispatch();
	const booking = useSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	);
	const bookingDetails = booking?.bookingDetails || [];
	const [dateCalendar, setDateCalendar] = useState<any>({
		selectedDay: formatDay(new Date()),
		daysOfWeek: [],
		currentMonthYear: formatDay(new Date()),
		selectedTime: !_.isEmpty(bookingDetails)
			? TimeHelper.toTimeZone(bookingDetails[0].startAtExpected).format(
					'hh:mmA'
			  )
			: '',
		existDisplay: [],
	});
	const [loading, setLoading] = useState(false);
	const [timeBlocks, setTimeBlocks] = useState<any>([]);
	const [stylists, setStylists] = useState<IEmployee[]>([]);
	const [currentStylist, setCurrentStylist] = useState<string>(
		bookingDetails[0].stylistId || ''
	);
	const isRequireStylist = useSelector(
		(state: RootState) =>
			state.BranchReducer.currentBranch?.technicianSelectionWhenBookingOnline
	)!;

	useEffect(() => {
		const currentDaysOfWeek: any = dates(new Date(), isMobile ? 4 : 6);
		setDateCalendar({
			...dateCalendar,
			daysOfWeek: currentDaysOfWeek,
			selectedDay: currentDaysOfWeek[0],
		});
	}, []);
	useEffect(() => {
		getAvailableStylistAndTimeBlocks(dateCalendar.selectedDay);
	}, [currentStylist]);

	const handleDecreaseWeek = () => {
		let fistDayOfLastWeek = '';
		if (isMobile) {
			fistDayOfLastWeek = moment(dateCalendar.daysOfWeek[0])
				.subtract(4, 'days')
				.format('YYYY-MM-DD');
		} else {
			fistDayOfLastWeek = moment(dateCalendar.daysOfWeek[1])
				.subtract(1, 'weeks')
				.format('YYYY-MM-DD');
		}

		const currentDaysOfWeek: any = dates(
			new Date(fistDayOfLastWeek),
			isMobile ? 4 : 6
		);
		setDateCalendar({
			...dateCalendar,
			daysOfWeek: currentDaysOfWeek,
		});
	};
	const handleIncreaseWeek = () => {
		const fistDayOfNextWeek = moment(dateCalendar.daysOfWeek.pop())
			.add(1, 'day')
			.format('YYYY-MM-DD');
		const currentDaysOfWeek: any = dates(
			new Date(fistDayOfNextWeek),
			isMobile ? 4 : 6
		);
		setDateCalendar({
			...dateCalendar,
			daysOfWeek: currentDaysOfWeek,
		});
	};

	const handleChooseCalendar = (day: any) => {
		const currentDaysOfWeek: any = dates(new Date(day), isMobile ? 4 : 6);
		setDateCalendar({
			...dateCalendar,
			daysOfWeek: currentDaysOfWeek,
			selectedDay: currentDaysOfWeek[0],
		});
		getAvailableStylistAndTimeBlocks(day);
	};

	const handleChooseOtherStylist = (stylist: Partial<IEmployee>) => {
		setCurrentStylist(stylist.id!);
		let newBookingDetails = Array.from(bookingDetails);
		const firstStylist = bookingDetails[0].stylistId;
		newBookingDetails = newBookingDetails?.map((bookingDetail) => {
			if (bookingDetail.stylistId === firstStylist) {
				if (stylist.serviceIds?.includes(bookingDetail.itemId || '')) {
					bookingDetail = {
						...bookingDetail,
						stylistId: stylist.id,
					};
				} else {
					bookingDetail = {
						...bookingDetail,
						stylistId: isRequireStylist ? undefined : 'Anyone',
					};
				}
			}
			return bookingDetail;
		});
		dispatch(
			CustomerBookingActions.updateBooking.request({
				...booking,
				bookingDetails: newBookingDetails,
			})
		);
	};

	const getAvailableStylistAndTimeBlocks = async (day: any) => {
		setLoading(true);
		const param: IStylistAndBlockTimeRequest = {
			date: formatDay(day),
			// serviceId: 'bb32d366-8aff-4498-f47e-08d9a12237eb', // TODO
			serviceId: booking?.bookingDetails![0].itemId,
			stylistId:
				booking?.bookingDetails![0].stylistId !== 'Anyone'
					? booking?.bookingDetails![0].stylistId
					: undefined,
			inputFrom: EBookingInputFrom.OnlineSite,
			duration: _.sumBy(booking?.bookingDetails, (x) => x.item?.duration || 0),
			bookingId: booking?.id,
		};
		const response = await BookingApiService.getAvailableStylistAndTimeBlocks(
			param
		);
		if (response?.succeeded) {
			response.data!.timeBlocks.map((item: any) => {
				item.label = TimeHelper.toTimeZone(item.time).format('hh:mmA');
			});
			const stylistsCanDoService = isRequireStylist
				? response.data!.stylists.filter(
						(stylist) => stylist.id !== booking?.bookingDetails![0].stylistId
				  )
				: ([noStylist].concat(response.data!.stylists) as IEmployee[]);
			setStylists(stylistsCanDoService.filter((x) => x.canBookedOnline));
			setTimeBlocks(
				groupTimeBlocks(
					response.data!.timeBlocks.filter(
						(x) => x.noStylistsAvailable === false && x.unavailable === false
					)
				)
			);
		}
		setLoading(false);
	};

	const handleChooseTime = (time: any) => {
		setDateCalendar({ ...dateCalendar, selectedTime: time.label });
		const newBooking: Partial<IBooking> = {
			...booking,
			startTimeExpected: time.time,
			date: TimeHelper.toTimeZone(moment.utc(time.time).toDate()).format(
				'YYYY-MM-DDT00:00:00'
			),
		};
		dispatch(CustomerBookingActions.updateBooking.request(newBooking));
	};

	return (
		<div className="pb-[420px]">
			<Calendar
				dateCalendar={dateCalendar}
				handleDecreaseWeek={() => handleDecreaseWeek()}
				handleChooseCalendar={(x) => handleChooseCalendar(x)}
				handleIncreaseWeek={() => handleIncreaseWeek()}
				isMobile={isMobile}
			/>
			<LoadingProvider loading={loading}>
				<TimeBlocks
					timeBlocks={timeBlocks}
					stylist={stylists}
					dateCalendar={dateCalendar}
					handleChooseTime={(item) => handleChooseTime(item)}
					isMobile={isMobile}
					handleChooseOtherStylist={(stylist) =>
						handleChooseOtherStylist(stylist)
					}
				/>
			</LoadingProvider>
		</div>
	);
};
